#header .py-12{
    padding-bottom:2rem !important;
    padding-top:2rem !important;
}
#header{
    --tw-shadow:none !important
}

@keyframes moveArrow {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .arrow-animation {
    animation: moveArrow 1.5s ease-in-out infinite;
  }
  