@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato&family=Nunito:wght@500;700&family=Poppins:wght@400;500;700&display=swap");

body {
  font-family: "Lato", sans-serif;
}
@media (min-width: 700px) and (max-width: 1024px) {
  #hero-section {
    display: flex;
    flex-direction: row;
  }

  #mobile-image {
    display: none; 
  }

  #desktop-image {
    width:50%;
    display: block;
  }
  #desktop-image img{
    width: 100%;
  }
  .pl-24{
    padding: 0px !important;
  }
}


